<template>
    <div class="storeGoods">
        <div class="storeGoodsHeader">
            <span></span>
            <router-link :to="{name:'storeDecorateStoreGoodsAdd'}"><el-button class="issueBtn">发布商品</el-button></router-link>
        </div>
        <div class="storeGoodsTable">
            <el-table :data="goodsData" style="margin-top:20px;width: 100%;flex: 1;border: #DCDCDC 1px solid" :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: 500, color: '#14141C', background: 'rgba(245,245,245,1)',height: '60px'}">
                <el-table-column prop="image_url" label="商品信息" align="left">
                    <template slot-scope="scope">
                        <div class="goodsInfo">
                            <img :src="scope.row.image_url" alt="">
                            <span class="text-overflow-2">{{scope.row.goods_name}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="class_name" label="行业分类" align="center"></el-table-column>
                <el-table-column prop="goods_price" label="价格" align="center">
                    <template slot-scope="scope">
                        <span>{{Number(scope.row.goods_price).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="goods_zhekou_price" label="折扣价" align="center">
                    <template slot-scope="scope">
                        <span>{{Number(scope.row.goods_zhekou_price).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" style="color: #1122D8" @click="editShop(scope.row.id)">修改商品</el-button>
                        <router-link tag="a" target="_blank" :to="{name:'studentStoreGoodDetail',query:{id: scope.row.id}}" style="margin-left: 10px">
                            <span class="goods-preview">查看</span>
                        </router-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    v-if="goodsData.length>10"
                    class="pages-center"
                    :current-page="adminPages.currentPageNum"
                    :page-size="adminPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPages.total"
                    @current-change="adminCurrentChange"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        name: "StoreGoods",
        data(){
            return {
                goodsData:[],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        created() {
            this.getListGoods()
        },
        methods: {
            ...mapActions([
                'setDecorationMenusList'
            ]),
            getListGoods(){
                let param = {
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum
                };
                this.$http.axiosGetBy(this.$api.listGoods, param, res=>{
                    if(res.code === 200){
                        this.goodsData = res.data.list;
                        if (this.$common.isCompetion()) {
                            this.setDecorationMenusList();
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            editShop(id){
                this.$router.push({
                    path:'/student/storedecorate/storegoodsAdd',
                    query: {
                        editID:id
                    }
                })
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getListGoods()
            },
        }
    }
</script>

<style scoped lang="scss">
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .storeGoods{
        height: calc(100vh - 160px);
        background: #ffffff;
        padding: 20px 24px;
        .storeGoodsHeader{
            display: flex;
            justify-content: space-between;
            .issueBtn{
                background: #FD4446;
                color: #FFFFFF;
                font-size: 14px;
                border: none;
                border-radius:2px;
            }
        }
    }
    .goodsInfo{
        display: flex;
        align-items: center;
        /*justify-content: center;*/
        /*padding-left: 60px;*/
        img{
            width: 58px;
            height: 58px;
        }
        span{
            margin-left: 10px;
        }
    }
</style>